import { InitOptions, JWTLoader, TilePositions } from '@app-types';
import WalletApi from '@components/WalletApi';

let initiated = false;

export default function init(initToken: JWTLoader, options?: InitOptions): WalletApi {
  if (initiated) throw new Error('Wallet already initialised');

  initiated = true;

  return new WalletApi({
    isVisible: true,
    tilePosition: TilePositions.BOTTOM_RIGHT,
    ...options,
    initToken,
  });
}
