import Calculator from '@utils/Calculator';

import productFinanceInfo from './productFinanceInfo';

const revolvingCreditFinanceInfo = (offers, valuePence) => {
  const instalments = offers.map((offer) => Calculator.instalments(valuePence, offer.term, offer.apr));

  const interestFreeInstalments = offers
    .filter((offer) => offer.apr === 0)
    .map((offer) => Calculator.instalments(valuePence, offer.term, offer.apr));

  const tagFormat = (a) => a / 100;
  const financeProductLogo = 'https://assets.dekopay.com/Logo/Lender/newpay.png';
  return productFinanceInfo(instalments, interestFreeInstalments, valuePence, financeProductLogo, tagFormat);
};

export default revolvingCreditFinanceInfo;
