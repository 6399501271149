import type { Instalment } from 'financeInfo/types';

import type { Offers } from '@utils/CheckoutAuthCertificate/types';

export default function calculateBlackhorseInstalment(basketAmount: number, offer: Offers): Instalment {
  const annualSimpleRate = offer.sar;
  const interestDays31 = Math.trunc(basketAmount * annualSimpleRate * (1 / 365) * 31 * 10000) / 10000;
  const startingPrinciple = Math.round((basketAmount + interestDays31) * 100) / 100;
  const monthlyRate = annualSimpleRate * (30.5 / 365);
  const monthlyRatePlus1 = monthlyRate + 1;
  const powerMonthlyRatePlus1 = monthlyRatePlus1 ** offer.term;
  const B = powerMonthlyRatePlus1 - 1;
  const A = startingPrinciple * monthlyRate * powerMonthlyRatePlus1;
  const monthlyPayments =
    annualSimpleRate === 0 ? Math.ceil((basketAmount / offer.term) * 100) / 100 : Math.ceil((A / B) * 100) / 100;
  const totalCost = annualSimpleRate === 0 ? basketAmount : monthlyPayments * offer.term;
  const interestToPay = totalCost - basketAmount;

  return {
    monthlyInstalment: monthlyPayments,
    total: totalCost,
    interestToPay,
    restrictions: offer.restrictions,
    apr: offer.apr,
  };
}
