import Cookies from 'js-cookie';

import { LenderName, ProductNames } from '@app-types';
import { CookieType } from '@utils/eligibilityBeaconPersistence/types';

import { FinanceArgs, FinanceResponse, VERSION } from './types';
import v1 from './v1';
import v2 from './v2';

const financeInfo = async (
  product: ProductNames,
  { value, initToken, term }: FinanceArgs,
  version = VERSION.v1,
  isInsideCheckout = false
): Promise<FinanceResponse> => {
  const eligibilityLenderCookie = Cookies.get(CookieType.EligibilityCheckLender);
  let financeProduct: ProductNames = product;

  if (eligibilityLenderCookie) {
    financeProduct =
      eligibilityLenderCookie === LenderName.BLACKHORSE ? ProductNames.BLACKHORSE_FLEXPAY : eligibilityLenderCookie;

    if (isInsideCheckout && financeProduct !== product) {
      return {
        message: 'Eligibility check failed',
      } as FinanceResponse;
    }
  }

  switch (version) {
    case VERSION.v2:
      return v2(financeProduct, { value, initToken });
    case VERSION.v1:
    default:
      return v1(financeProduct, { value, initToken, term });
  }
};

export default financeInfo;
