import { NewPaySplash, ZipCheckoutSplash, ZipNonPromotionalSplash } from 'components-typescript-react';
import React from 'react';

import { type CreateApplicationSuccess, ProductNames } from '@app-types';
import ProductsList from '@components/ProductsList';
import { StartPreCheckoutWorkflow } from '@components/WorkflowStep/types';
import createIFrameData from '@utils/createIFrameData';
import { InitCertificateLens } from '@utils/InitCertificate';
import ZipInstalments from '@utils/ZipInstalments';

export interface SplashPreCheckoutProps {
  selectedProduct: ProductNames;
  isLegacyProducts: boolean;
  basketValue: number;
  availableProducts: ProductNames[];
  initCertificate: InitCertificateLens;
  createApplication: (withPolling?: boolean) => Promise<CreateApplicationSuccess>;
  setSelectedProduct: React.Dispatch<React.SetStateAction<ProductNames>>;
  startPreCheckoutWorkflow(params: StartPreCheckoutWorkflow): void;
  onClose: () => void;
}

const SplashPreCheckout: React.FC<SplashPreCheckoutProps> = ({
  selectedProduct,
  availableProducts,
  isLegacyProducts,
  initCertificate,
  createApplication,
  onClose,
  basketValue,
  setSelectedProduct,
  startPreCheckoutWorkflow,
}) => {
  const [isAlreadySelected, setIsAlreadySelected] = React.useState(false);
  const isRevolvingCreditProduct = availableProducts.length === 1 && selectedProduct === ProductNames.REVOLVING_CREDIT;

  const onSetSelectedProduct = (product: ProductNames) => {
    setSelectedProduct(product);
    setIsAlreadySelected(true);
  };

  React.useEffect(() => {
    if (selectedProduct !== ProductNames.SPLIT && !isAlreadySelected) {
      startPreCheckoutWorkflow(createIFrameData({ initCertificate, selectedProduct }));
    }
  }, [isAlreadySelected, selectedProduct]);

  if (isLegacyProducts && !isAlreadySelected && !isRevolvingCreditProduct && availableProducts.length > 0) {
    return <ProductsList onSetSelectedProduct={onSetSelectedProduct} products={availableProducts} />;
  }

  if (selectedProduct === ProductNames.SPLIT) {
    if (!isAlreadySelected) {
      return <ZipNonPromotionalSplash onClose={onClose} instalments={[25, 25, 25, 25]} maxAmount={1000} />;
    }

    return (
      <ZipCheckoutSplash
        onClose={onClose}
        amount={basketValue}
        instalments={ZipInstalments(basketValue)}
        onContinue={createApplication}
        onOutsideLimits={() => {}}
        minAmount={0}
        maxAmount={1000}
      />
    );
  }

  if (selectedProduct === ProductNames.REVOLVING_CREDIT && isAlreadySelected) {
    return <NewPaySplash onClose={onClose} onLogin={createApplication} onWillIQualify={createApplication} />;
  }

  return null;
};

export default SplashPreCheckout;
