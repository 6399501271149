import { ProductNames, type SchemaObject } from '@app-types';
import { encodeBase64, INSTALMENT_PAGE_URL } from '@components/WorkflowStep/productInfo';
import { WorkflowType } from '@components/WorkflowStep/types';
import getOriginOfUrl from '@components/WorkflowStep/utils/getOriginOfUrl';
import type { InitPayloadSplash } from '@utils/CheckoutAuthCertificate/types';
import type { InitCertificateLens } from '@utils/InitCertificate';

export type IFrameData = { url: string; originUrl: string; schema?: SchemaObject; type?: WorkflowType };

const createInstalmentIFrameData = (initCertificate: InitCertificateLens, productAmount?: number): IFrameData => {
  const url = new URL(INSTALMENT_PAGE_URL);

  if (productAmount) url.searchParams.set('promotionalInfo', productAmount.toString());
  if (initCertificate.repExamples) {
    url.searchParams.set('repExamples', encodeBase64(initCertificate.repExamples));
  }

  return {
    url: url.toJSON(),
    originUrl: getOriginOfUrl(INSTALMENT_PAGE_URL),
  };
};

const createStandaloneIFrameData = ({ asset: url, type, schema }: InitPayloadSplash): IFrameData => ({
  url,
  schema,
  type,
  originUrl: getOriginOfUrl(url),
});

const createRevolvingCreditIFrameData = (
  initCertificate: InitCertificateLens,
  selectedProduct: ProductNames,
  productAmount?: number
): IFrameData => {
  const url = new URL(initCertificate.getProductUrl(selectedProduct));
  url.pathname = '/splash';
  url.searchParams.set('retailer', initCertificate.retailerId);
  url.searchParams.set('service', 'NEW_PAY');
  url.searchParams.set('retailerName', initCertificate.retailerName);

  if (productAmount) url.searchParams.set('promotionalInfo', productAmount.toString());

  return {
    url: url.toJSON(),
    originUrl: getOriginOfUrl(INSTALMENT_PAGE_URL),
  };
};

const createBlackHorseIFrameData = ({ asset: url, type, schema }: InitPayloadSplash): IFrameData => ({
  url,
  originUrl: getOriginOfUrl(url),
  type,
  schema,
});

export type CreateIFrameDataParams = {
  initCertificate: InitCertificateLens;
  selectedProduct: ProductNames;
  productAmount?: number;
};

const createIFrameData = ({ initCertificate, selectedProduct, productAmount }: CreateIFrameDataParams): IFrameData => {
  switch (selectedProduct) {
    case ProductNames.REVOLVING_CREDIT:
      return createRevolvingCreditIFrameData(initCertificate, selectedProduct, productAmount);
    case ProductNames.NEWPAY:
    case ProductNames.BLACKHORSE_FLEXPAY:
      return createBlackHorseIFrameData(initCertificate.getSplash(selectedProduct));
    case ProductNames.NEWPAY_STANDALONE:
    case ProductNames.BLACKHORSE_FLEXPAY_STANDALONE:
      return createStandaloneIFrameData(initCertificate.getSplash(selectedProduct));
    case ProductNames.INSTALMENT:
    case ProductNames.DEKO_MONTHLY:
    case ProductNames.MULTI_LENDER:
    default:
      return createInstalmentIFrameData(initCertificate, productAmount);
  }
};

export default createIFrameData;
