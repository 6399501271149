import InitCertificate, { InitCertificateLens } from '@utils/InitCertificate';

import { dummyPrecheckoutPersistance, PrecheckoutPersistance, PrecheckoutPersistanceKeys } from './types';

const clearPrecheckoutState = () => {
  Object.keys(dummyPrecheckoutPersistance).forEach((key: PrecheckoutPersistanceKeys) => {
    sessionStorage.removeItem(key);
  });
};

const getPrecheckoutStateByKey = <K extends PrecheckoutPersistanceKeys = PrecheckoutPersistanceKeys>(
  key: K
): PrecheckoutPersistance[K] | null => {
  const value = sessionStorage.getItem(key);
  if (value != null && value !== '') {
    return JSON.parse(value) as PrecheckoutPersistance[K];
  }
  return null;
};

const setPrecheckoutStateByKey = <K extends PrecheckoutPersistanceKeys = PrecheckoutPersistanceKeys>(
  key: K,
  value: PrecheckoutPersistance[K]
) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

const getPersistedInitToken = (): InitCertificateLens | null => {
  const initToken = getPrecheckoutStateByKey('initToken');
  try {
    if (initToken != null && initToken !== '') {
      const parsedInitToken = InitCertificate(initToken);
      if (
        'expiry' in parsedInitToken &&
        parsedInitToken.expiry instanceof Date &&
        parsedInitToken.expiry > new Date()
      ) {
        return parsedInitToken;
      }
      clearPrecheckoutState();
    }
  } catch (e) {
    clearPrecheckoutState();
  }
  return null;
};

export const PrecheckoutStateManager = {
  clearPrecheckoutState,
  getPrecheckoutStateByKey,
  setPrecheckoutStateByKey,
  getPersistedInitToken,
};
