import { Molecules, Spinner, ZipSuccessPage } from 'components-typescript-react';
import React from 'react';

import { ProductNames } from '@app-types';
import { AppPropManager } from '@utils';

interface SuccessPageProps {
  selectedProduct: ProductNames;
  onClose: () => void | Promise<void>;
}

const SuccessPage: React.FC<SuccessPageProps> = ({ onClose, selectedProduct = ProductNames.GENERIC }) => {
  switch (selectedProduct) {
    case ProductNames.BLACKHORSE_FLEXPAY:
    case ProductNames.BLACKHORSE_FLEXPAY_STANDALONE:
    case ProductNames.NEWPAY:
    case ProductNames.NEWPAY_STANDALONE:
      return <Molecules.Loader lender={AppPropManager.activeLender} />;
    case ProductNames.SPLIT:
      return <ZipSuccessPage onClose={onClose} />;
    case ProductNames.INSTALMENT:
    case ProductNames.DEKO_MONTHLY:
    case ProductNames.MULTI_LENDER:
    case ProductNames.REVOLVING_CREDIT:
      return <Spinner text="Processing payment" fadeInText showText />;
    default:
      return null;
  }
};

export default SuccessPage;
