import { createContext } from 'react';

import {
  ApiAdapter,
  Application,
  ApplicationStatus,
  ApplicationStatusPayload,
  EnhancedApplicationStatusPayload,
} from '@app-types';
import { exponentialBackoff } from '@utils/exponentialBackoff';
import { PublicApiCapturedEventPayload } from '@utils/PublicApiAdapter/types';

import { ApiClientContextInterface, Listener } from '../types';

let apiAdapter: ApiAdapter;
let pollTimers: number[] = [];
let timeoutFunction = setTimeout;
const listeners: Listener[] = [];
let pollUrl = null;

export const getListenerCount = () => listeners.length;

export const clearPollTimers = () => {
  pollTimers.map((timer) => {
    clearTimeout(timer);
    return null;
  });
  pollTimers = pollTimers.filter((timer) => timer !== null);
};

const getFailureReason = (e: unknown): string => {
  if (
    typeof e === 'object' &&
    'response' in e &&
    typeof e.response === 'object' &&
    'status' in e.response &&
    typeof e.response.status === 'number'
  ) {
    if (e.response.status === 401) {
      return 'Session expired';
    }
    if (e.response.status < 500) {
      return 'Client error';
    }
  }
  return 'Connection error';
};

const callListeners = (status: ApplicationStatusPayload, appId: string) => {
  listeners.forEach((listener: Listener) => {
    listener(status, appId);
  });
};

const statusCallback = async (interval: number, cac: string) => {
  try {
    const status = await exponentialBackoff(async () => apiAdapter.getStatus(pollUrl, cac), interval, {
      functionName: 'getStatus',
      timeoutFn: timeoutFunction,
    });
    callListeners(status, '');
    clearPollTimers();
    pollTimers.push(timeoutFunction(statusCallback, interval, interval, cac, 0));
  } catch (e: unknown) {
    const error = e;
    const failureReason = getFailureReason(error);
    callListeners(
      {
        status: ApplicationStatus.error,
        reason: failureReason,
      },
      ''
    );
  }
};

const pollStatus = async (interval: number, cac: string) => {
  statusCallback.bind(this);
  clearPollTimers();
  pollTimers.push(timeoutFunction(statusCallback, interval, interval, cac, 0));
};

export const context: ApiClientContextInterface = {
  appId: '',
  async createApplication(product, cac): Promise<Application> {
    const data = await apiAdapter.createApplication(product, cac);

    if (data !== undefined && 'appId' in data) {
      this.appId = data.appId;
    }

    return data;
  },
  subscribe: (listener: Listener) => {
    if (!listeners.includes(listener)) {
      listeners.push(listener);
    }
  },
  unsubscribe: (listener: Listener) => {
    const index = listeners.indexOf(listener);
    if (index >= 0) {
      listeners.splice(index, 1);
    }

    if (pollTimers) clearPollTimers();
  },
  startPolling(url, interval, cac) {
    if (pollTimers) clearPollTimers();
    pollUrl = url;
    pollStatus(interval, cac);
  },
  async sendPayloadPost(payload: object, submit: string, cac: string) {
    const result = await exponentialBackoff<EnhancedApplicationStatusPayload>(
      () => apiAdapter.sendPayload(payload, submit, cac),
      1500,
      { functionName: 'sendPayloadPost', timeoutFn: timeoutFunction }
    );
    this.appId = result?.appId;
    return result;
  },
  captureEvent: async (payload: PublicApiCapturedEventPayload, url: string, cac: string) =>
    apiAdapter.captureEvent(payload, url, cac),
};

export const setApiAdapter = (adapter: ApiAdapter): void => {
  apiAdapter = adapter;
};

export const setTimeoutFunction = (fn: (cb: () => void, time: number, ...rest) => number): void => {
  timeoutFunction = fn as typeof setTimeout;
};

export const ApiClientContext = createContext(context);
